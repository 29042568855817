<template>
  <div class="about-view">
    <div class="about-view-container">
      <h1 class="title-police">À Propos de We Are Pirates</h1>
      <p>
        Bienvenue sur We Are Pirates, le repaire en ligne où tout amateur de piraterie peut enfin donner vie à son propre Jolly Roger numérique ! L'histoire de We Are Pirates est née de la quête d'un trésor unique, inspiré par des Nakamas intrépides tels que @lemontcorvo et @goji, qui ont hissé fièrement leurs propres drapeaux pirates personnalisés. Face à l'absence de solutions rapides sur la toile, l'idée de créer We Are Pirates a germé, donnant naissance au premier site de création de Jolly Roger.
      </p>
      <h1 class="title-police">Notre Mission et Vision</h1>
      <p>
        Chez We Are Pirates, notre mission est d'offrir à chaque pirate, qu'il soit un fan inconditionnel de One Piece, un amateur d'aventures en haute mer, un passionné de piraterie, ou un "Ratpi" en quête d'un emblème, la possibilité de posséder son propre Jolly Roger We Are Pirates, un symbole qui le représente fièrement. Nous aspirons à rendre la personnalisation de drapeaux pirates numériques accessible à tous, permettant ainsi à chaque utilisateur de créer un Jolly Roger qui reflète son âme de pirate.
      </p>
      <h1 class="title-police">Les Détails sur les Drapeaux : </h1>
      <p>
        We Are Pirates met à ta disposition une collection impressionnante de 350 éléments. Les 30% de ces éléments sont offerts gracieusement, tandis que le reste est accessible mais verrouillé pour le téléchargement. Pour débloquer ces trésors cachés, il te suffit d'acquérir des crédits. Grâce à ces crédits, le monde entier de We Are Pirates s'ouvrira à toi.
      </p>
      <h1 class="title-police">L'Art des Crédits :</h1>
      <p>
        1 drapeau 72 DPI = 1 Crédit
        <br><br>
        1 drapeau 300 DPI = 2 Crédits
        <br><br>
        1 Crédit = 0,99€
        <br><br>
        2 Crédits = 1,99€
        <br><br>
        3 Crédits = 2,99€
        <br><br>
        Offre -20% : 5 Crédits = 3,99€
        <br><br>
        Offre -30% : 10 Crédits = 6,99€
        <br><br>
        Offre -50% : 20 Crédits = 9,99€
        <br><br>
        We Are Pirates est le seul site qui te permet de créer ton propre drapeau inspiré de One Piece avec facilité et plaisir.
      </p>

      <h1 class="title-police">Le Capitaine à la Barre</h1>
      <p>
        Le fondateur - Un pirate en quête de liberté !
      </p>
      <h1 class="title-police">Engagement envers la Qualité</h1>
      <p>
        Nos drapeaux sont téléchargeables en qualité 72 DPI pour une utilisation numérique optimale ou en qualité 300 DPI pour une impression de qualité supérieure.
      </p>
      <h1 class="title-police">Contacte-nous :</h1>
      <p>
        Email : <a href="mailto:wearepirates.cie@gmail.com">wearepirates.cie@gmail.com</a>
        <br><br>
        Instagram : wearepirates_cie
        <br><br>
        Facebook : We Are Pirates
        <br><br>
        N'hésite pas à nous contacter pour toute question. Sois le bienvenu à bord !
      </p>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "AboutView",
  // components: {
  //   ProfileLayout
  // }
};
</script>

<style>
.about-view {
  margin: 56px;
}

.about-view-container {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  max-width: 1616px;
  /* margin: 56px; */
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}

@media screen and (max-width: 991px) {
  .about-view-container {
    background-color: transparent;
    padding: 0;
  }

  .about-view {
    margin: 28px;
  }
}
</style>