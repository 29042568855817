<template>
    <div class="single-flag-element">
        <div class="element-container row w-100">
            <!-- {{ "@/assets/" + this.elementImageFileName }}
            {{ this.elementImageFileName }}
            {{ image }} -->
            <div v-if="!this.isNewElement" class="col-2">
                <img v-bind:src="image">
            </div>
            <div class="col-9 form-container" style="padding-bottom: 8px;">
                <div class="row mt-2">
                    <!-- <div class="col-1" v-if="!this.isNewElement">
                        <p class="d-inline-block">
                            {{ this.elementId }}
                        </p>
                    </div> -->
                    <div class="col-3">
                        <!-- <p class="d-inline-block">
                            Nom :
                        </p> -->
                        <input type="text" class="edit-flag-input form-control" placeholder="Nom de l'élément"
                            aria-label="name" v-model="formName">
                    </div>
                    <div class="col-3">
                        <!-- <p class="d-inline-block">
                            ImageFileName :
                        </p> -->
                        <input type="text" class="edit-flag-input form-control" placeholder="Nom de l'image"
                            v-model="formImageFileName" />
                    </div>
                    <div class="col-3">
                        <!-- <p class="d-inline-block">
                            ImageFileName :
                        </p> -->
                        <select class="form-select" v-model="formType">


                            <option value="06 CRÂNES - SKULLS">06 CRÂNES - SKULLS</option>
                            <option value="04 MÂCHOIRES - JAWS">04 MÂCHOIRES - JAWS</option>
                            <option value="17 CORNES - HORNS LEFT">17 CORNES - HORNS LEFT</option>
                            <option value="17 CORNES - HORNS RIGHT">17 CORNES - HORNS RIGHT</option>
                            <option value="09 YEUX - EYES LEFT">09 YEUX - EYES LEFT</option>
                            <option value="09 YEUX - EYES RIGHT">09 YEUX - EYES RIGHT</option>
                            <option value="05 PUPILS LEFT">05 PUPILS LEFT</option>
                            <option value="05 PUPILS RIGHT">05 PUPILS RIGHT</option>
                            <option value="07 DENTS - TOOTH DOWN">07 DENTS - TOOTH DOWN</option>
                            <option value="07 DENTS - TOOTH UP">07 DENTS - TOOTH DOWN</option>
                            <option value="16 CHAPEAUX CASQUE  - HATS HELMETS">16 CHAPEAUX CASQUE  - HATS HELMETS</option>
                            <option value="03 CROIX - CROSS H">03 CROIX - CROSS H</option>
                            <option value="03 CROIX - CROSS V">03 CROIX - CROSS V</option>
                            <option value="03 CROIX - CROSS 01">03 CROIX - CROSS 01</option>
                            <option value="03 CROIX - CROSS 02">03 CROIX - CROSS 02</option>

                            <!-- <option value="06 CRÂNES - SKULLS">06 SKULLS</option>
                            <option value="06 CRÂNES - SKULLS">07 SKULLS</option>
                            <option value="06 CRÂNES - SKULLS">08 SKULLS</option>
                            <option value="06 CRÂNES - SKULLS">06 CRÂNES - SKULLS</option>
                            <option value="06 CRÂNES - SKULLS">06 CRÂNES - SKULLS</option>

                            <option value="06 CRÂNES - SKULLS">06 CRÂNES - SKULLS</option>
                            <option value="06 CRÂNES - SKULLS">06 CRÂNES - SKULLS</option>
                            <option value="06 CRÂNES - SKULLS">06 CRÂNES - SKULLS</option>
                            <option value="06 CRÂNES - SKULLS">06 CRÂNES - SKULLS</option>
                            <option value="06 CRÂNES - SKULLS">06 CRÂNES - SKULLS</option> -->


                            <!-- TODO: delete -->

                            <option value="upper-skull">Crâne</option>
                            <option value="jaw">Machoire</option>
                            <!-- <option value="cross-back">Cross-back</option> -->
                            <!-- <option value="eyes">Yeux</option> -->
                            <!-- <option value="toto">Toto</option> -->

                            <option value="country-flag">Drapeau de pays</option>
                            <option value="cross-top-left-to-bottom-right">cross-top-left-to-bottom-right</option>
                            <option value="cross-top-right-to-bottom-left">cross-top-right-to-bottom-left</option>
                            <option value="cross-top-to-bottom">cross-top-to-bottom</option>
                            <option value="cross-left-to-right">cross-left-to-right</option>
                            <option value="left-eye">left-eye</option>
                            <option value="right-eye">right-eye</option>

                        </select>
                    </div>
                    <div class="col-3">
                        Premium ?                    
                        <input type="checkbox" name="premium" v-model="formPremium" />
                    </div>
                    <!-- <p>
                        Path de l'image :
                        {{ this.elementImageFileName }}
                    </p> -->
                </div>

                <div v-if="!this.isNewElement" style="text-align: right;" class=" mt-3 mb-3">
                    <div>
                        <button class="btn btn-primary" v-on:click="this.updateElement()">
                            Enregistrer
                        </button>
                        <button class="btn btn-danger" v-on:click="this.deleteElement()" style="margin-left: 10px;">
                            Supprimer
                        </button>
                    </div>
                </div>
            </div>

            <div v-if="this.isNewElement" class="col-3 text-center">
                <button class="btn btn-success" v-on:click="this.createElement()" style="margin-top: 7px;">
                    Ajouter
                </button>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios'

export default {
    name: "SingleFlagElement",
    props: {
        elementId: {
            type: Number,
            required: false
        },
        elementName: {
            type: String,
            required: false
        },
        elementImageFileName: {
            type: String,
            required: false
        },
        elementType: {
            type: String,
            required: false
        },
        isNewElement: {
            type: Boolean,
            required: false,
            default: false
        },
        elementPremium: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            image: null,
            formName: "",
            formPremium: "",
            formImageFileName: "",
            formType: ""
        }
    },
    components: {
    },
    // watch: {
    //     elementImageFileName() {
    //         console.log("oui update")
    //         this.image = require("@/assets/upper_1.png")
    //     },
    // },
    mounted() {
        // console.log(this.elementImageFileName)
        if (!this.isNewElement)
            this.image = process.env.BASE_URL + 'flag-elements-img/' + this.elementImageFileName
        this.formName = this.elementName
        this.formPremium = this.elementPremium == 1 ? true : false
        this.formImageFileName = this.elementImageFileName
        this.formType = this.elementType
    },
    methods: {
        updateElement() {
            const singleElement = {
                name: this.formName,
                "image_url": this.formImageFileName,
                "type": this.formType,
                "premium": this.formPremium,
                id: this.elementId
            };

            axios
                .post('https://we-are-pirates.herokuapp.com' + '/admin/update', singleElement)
                .then(() => {
                    // this.info = response['data'].data
                    // console.log(this.info[0])
                    console.log("done update")
                    location.reload()
                })
        },
        deleteElement() {
            axios
                .post('https://we-are-pirates.herokuapp.com' + '/admin/delete', { id: this.elementId })
                .then(() => {
                    // this.info = response['data'].data
                    // console.log(this.info[0])
                    location.reload()
                })
        },
        createElement() {
            console.log(this.formName)
            if (!this.formName || !this.formImageFileName || !this.formType) {
                alert("Informations manquantes pour la création de l'élément")
                return
            }
            console.log(this.formType)
            const singleElement = {
                name: this.formName,
                premium: this.formPremium,
                "image_url": this.formImageFileName,
                "type": this.formType,
            };

            axios
                .post('https://we-are-pirates.herokuapp.com' + '/admin/create', singleElement)
                .then(() => {
                    // this.info = response['data'].data
                    // console.log(this.info[0])
                    console.log("done create")
                    location.reload()
                })
        }
    },
};
</script>

<style scoped>
.element-container {
    margin-top: 10px;
    /* border: 2px solid black; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: left;
    background-color: white;
}

.element-container img {
    /* height: 70px; */
    width: 100%;
    padding: 0;
}
</style>
  