<template>
  <div class="faq-view">
    <div class="faq-view-container">
      <h1 class="title-police">FAQ</h1>
      <h5 class="mt-4 mb-3 fw-bold">Introduction</h5>
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Est-ce que je peux profiter du site sans me prendre la tête à créer un compte ?</template>
        <template v-slot:answer>Bien sûr ! Tu peux explorer le site et laisser libre cours à ton imagination pour réaliser un max de drapeaux sans te faire chier à créer un compte. Mais bon, si tu veux sauvegarder, télécharger et partager tes créations, fais pas le timide, inscris-toi. C'est rapide et easy !</template>
      </QuestionWithHiddenAnswer>

      <QuestionWithHiddenAnswer>
        <template v-slot:question>Comment je fais mon drapeau ?</template>
        <template v-slot:answer>Sur la page "Créer un Drapeau", fais ton choix parmi les 30 items gratuits ou les 270 items payants. Pour sélectionner un item, clique sur la catégorie de ton choix puis sur l’item de ton choix. L’élément sélectionné apparaît sur la toile. Sélectionne d’autres items pour réaliser ton jolly roger ! Quand tu es satisfait de ta réalisation, clique sur “sauvegarder” si tu souhaites conserver, télécharger ou partager ta création.</template>
      </QuestionWithHiddenAnswer>

      <QuestionWithHiddenAnswer>
        <template v-slot:question>Est-ce que je peux changer l’ordre des items ?</template>
        <template v-slot:answer>Oui ! Pour cela, il faut cliquer sur “réorganiser les éléments” pour ouvrir le modal qui te permettra de faire passer en dessous ou au-dessus le ou les items de ton choix.</template>
      </QuestionWithHiddenAnswer>

      <QuestionWithHiddenAnswer>
        <template v-slot:question>Le cadenas sur les items, ça veut dire quoi ?</template>
        <template v-slot:answer>Cadenas fermé = payant, tout simplement. Si tu kiffes sur un élément payant, tu auras besoin de crédits pour le débloquer.</template>
      </QuestionWithHiddenAnswer>

      <QuestionWithHiddenAnswer>
        <template v-slot:question>Comment je fais pour acheter des crédits ?</template>
        <template v-slot:answer>Pour acheter des crédits, il faut soit cliquer sur “Acheter Crédit” dans le pop-up de paiement qui s’affiche lorsque tu cliques sur un item diamanté, soit aller sur la page "Mon Compte" sous "Mes crédits".</template>
      </QuestionWithHiddenAnswer>
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Et si je n’ai pas assez de crédits, qu’est-ce qui se passe ?</template>
        <template v-slot:answer>Si t'as plus ou pas assez de crédits pour choper ton drapeau, le pop-up de paiement s’affiche lors de chaque action payante. Achète ou rachète des crédits et c'est bon :)</template>
      </QuestionWithHiddenAnswer>
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Où est-ce que je peux voir combien de crédits j’ai sur mon compte ?</template>
        <template v-slot:answer>Chouffe dans l’entête en haut, à côté de l'icône "Crédit", y'a ton solde de crédits. Clic dessus et atterris direct sur ta page "Mon compte" pour plus de détails.</template>
      </QuestionWithHiddenAnswer>
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Comment je télécharge mon drapeau ?</template>
        <template v-slot:answer>Sur la page "Mon Drapeau", choisis la qualité 72 DPI ou 300 DPI et clique sur "Télécharger". Si t'as des crédits dans la poche, le téléchargement se lance.</template>
      </QuestionWithHiddenAnswer>
      <!-- <h5 class="mt-4 mb-3 fw-bold">Introduction</h5> -->
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Quels formats de fichiers sont disponibles ?</template>
        <template v-slot:answer>Les drapeaux 72 DPI sont disponibles au format JPEG, et conviennent parfaitement pour des photos profils ou des fonds d’écran. Les drapeaux 300 DPI sont disponibles au format PNG, ce format d'image est couramment utilisé et prend en charge une haute résolution, ce qui garantit une qualité optimale pour une utilisation en ligne ou pour l'impression sur différents supports.</template>
      </QuestionWithHiddenAnswer>
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Gestion des drapeaux en mode boss?</template>
        <template v-slot:answer>Sur ta page "Mon Compte", gère les drapeaux de ta galerie. Renomme-les, sauvegarde-les, télécharge-les, partage-les, ou modifie-les si t'as assez de crédit.!</template>
      </QuestionWithHiddenAnswer>
      <QuestionWithHiddenAnswer>
        <template v-slot:question>Besoin de parler avec les pirates de We Are Pirates?</template>
        <template v-slot:answer>Mail-nous à <a href="mailto:wearepirates.cie@gmail.com">wearepirates.cie@gmail.com</a> ou suis-nous sur Instagram (wearepirates_cie) et Facebook (We Are Pirates) pour les dernières actus et updates.</template>
      </QuestionWithHiddenAnswer>
    </div>
  </div>
</template>
  
<script>
import QuestionWithHiddenAnswer from "@/components/FAQ/QuestionWithHiddenAnswer.vue";

export default {
  name: "FAQView",
  components: { QuestionWithHiddenAnswer },
};
</script>

<style>
.faq-view {
  margin: 56px;
}

.faq-view-container {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  max-width: 1616px;
  /* margin: 56px; */
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}

@media screen and (max-width: 991px) {
  .faq-view-container {
    background-color: transparent;
    padding: 0;
  }

  .faq-view {
    margin: 28px;
  }
}
</style>