<template>
  <div class="preview-completed-flag d-block d-lg-none">
    <div id='drawing'>
      <span v-for="(chosenFlagElement, chosenFlagElementType, index) in this.$store.state.chosenFlagElements"
        v-bind:key="'drawing-' + index">

        <img v-if="chosenFlagElement && chosenFlagElement['image_url']" v-bind:src="this.publicPath + chosenFlagElement['image_url']">
      </span>
      <img class="option-img" :src="watermarkPath" v-if="shouldDisplayWatermark">
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewCompletedFlag",
  data() {
    return {
      watermarkPath: process.env.BASE_URL  + 'logo watermark/watermark 25.png',
      publicPath: process.env.BASE_URL + "flag-elements-img/",
    }
  },
  props: {
    matermark: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    shouldDisplayWatermark() {
      return (this.$store.getters.containsPremiumElements)
    }
  }
}
</script>

<style scoped>
#drawing {
  position: relative;
  width: 100vw;
  /* max-width: 50%; */
  /* width: 800px !important; */
  /* border: solid 1px black; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-left: auto;
  margin-right: 0;
  height: 30vw;
  border-radius: 15px;
  /* background-color: black; */
  border: 1px solid white;
}

#drawing img {
  position: absolute;
  left: 26vw;
  right: 0;
  height: 100%;
  border-radius: 15px;
}
@media screen and (max-width: 550px) {
  #drawing {
    height: 62vw;
  }
  #drawing img {
    left: 0;
  }
}

</style>