<template>
  <div class="flag-preview position-relative h-100">
    <!-- {{ this.flagElements }} -->
    <span v-for="(index, flagElementType) in flagElements" v-bind:key="'flag-element-' + index">
      <!-- toto

      {{ this.flagElements[flagElementType] }}
      tata -->
      <img class="h-100 position-absolute top-0 start-0" v-if="this.flagElements[flagElementType] !== '' && this.flagElements[flagElementType]['image_url']"
        v-bind:src="this.publicPath + this.flagElements[flagElementType]['image_url']">
    </span>
  </div>
</template>

<script>

export default {
  name: "FlagPreview",
  data: () => ({
    publicPath: process.env.BASE_URL + 'flag-elements-img/',
  }),
  props: {
    flagElements: {
      type: Object,
      required: true
    }
  }
}

</script>

<style></style>