
<template>
  <VueFinalModal class="flex justify-center items-center modal-sign-in"
    content-class="flex flex-col max-w-xl m-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2">
    <h2 class="mb-4 title-police" style="font-size: 24px;">Terms & conditions</h2>
    <div class="d-flex mb-3">
      <input style="accent-color: #DDBD3C; margin-right: 5px;" type="checkbox" id="checkbox-selling-conditions"
        v-model="sellingConditionsCheckbox" />
      <label style="margin-left: 5px" for="checkbox-selling-conditions">
        J’ai lu et accepte les <span class="text-decoration-underline fw-bold">conditions générales de vente</span>
      </label>
    </div>
    <div class="d-flex">
      <input style="accent-color: #DDBD3C; margin-right: 5px" type="checkbox" id="checkbox-terms-and-conditions"
        v-model="termsAndConditionsCheckbox" />
      <label style="margin-left: 5px" for="checkbox-terms-and-conditions">
        J’ai lu et accepte les <span class="text-decoration-underline fw-bold">terms and conditions de We Are
          Pirates</span>
      </label>
    </div>


    <div class="w-100 mt-4" style="text-align: right">
      <div>
        <button class="btn btn-primary connexion-button white-button" style="margin-right: 16px; font-weight: 600;"
          v-on:click="this.closeModal()">
          Annuler
        </button>
        <button class="btn btn-primary connexion-button yellow-button" style="font-weight: 600;"
          v-bind:disabled="!(this.sellingConditionsCheckbox && this.termsAndConditionsCheckbox)"
          v-on:click="this.agreedConditions">
          Continuer
        </button>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'

export default {
  name: "TermsAndConditionsModal",
  components: { VueFinalModal },
  props: {
    closeModal: {
      type: Function
    }
  },
  data() {
    return {
      sellingConditionsCheckbox: false,
      termsAndConditionsCheckbox: false
    }
  },
  methods: {
    agreedConditions() {
      // document.cookie = "conditions=true";
      localStorage.setItem('conditions', true)
      this.$router.push('/flag-creator');

    }
  },
}
</script>

<style scopped>

input[type="checkbox"]:checked {
  background-color: #DDBD3C;
}</style>