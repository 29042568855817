
<template>
  <div class="personnal-infos mt-5">
    <div class="text-center" v-if="this.userInfosLoading == true">
      <div class="spinner-border mt-4" role="status" />
    </div>
    <div v-else class="profile-infos-form-container">
      <div class="identity-container">
        <h5 class="font-weight-600">
          Identité
        </h5>
        E-mail
        <input type="text" class="form-control mb-3 mt-2" placeholder="Email" aria-label="email" v-model="inputEmail">
        Genre
        <select v-model="this.inputGender" class="form-select mb-3 mt-2" aria-label="Default select example">
          <option value="" selected>Non renseigné</option>
          <option value="Homme">Homme</option>
          <option value="Femme">Femme</option>
          <option value="Autre">Autre</option>
        </select>
        Date de naissance
        <input type="date" class="form-control mb-3 mt-2" v-model="this.inputBirthdate" min="1997-01-01"
          max="2030-12-31" />
        <div>
          <button class="btn btn-primary connexion-button yellow-button" style="font-weight: 600;"
            v-on:click="this.updateUserInfos">
            Sauvegarder mes informations
          </button>
        </div>
      </div>

      <div class="change-password-container mt-5">
        <h5 class="font-weight-600">
          Changer de mot de passe
        </h5>
        <div style="margin-right: 16px;">
          Nouveau mot de passe
          <input type="text" class="form-control mb-3 mt-2" placeholder="Mot de passe" aria-label="password"
            v-model="inputPassword">
        </div>
        <div>
          Confirmer nouveau mot de passe
          <input type="text" class="form-control mb-3 mt-2" placeholder="Mot de passe" aria-label="password-confirm"
            v-model="inputConfirmPassword">
        </div>
        <br>
        <div>
          <button class="btn btn-primary connexion-button yellow-button" style="font-weight: 600;"
            v-on:click="this.updateUserPassword">
            Sauvegarder mon mot de passe
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'



export default {
  name: "PersonnalInfos",
  data() {
    return {
      inputEmail: "",
      inputPassword: "SuperPassword",
      inputConfirmPassword: "SuperPassword",
      inputBirthdate: "",
      inputGender: "",
      userInfosLoading: true
    }
  },
  mounted() {
    var uniqueCode = this.$store.state.connectedUserUniqueCode

    console.log("unique code ::: " + uniqueCode)
    axios
      .get('https://we-are-pirates.herokuapp.com' + "/admin/get_user_infos/" + uniqueCode)
      .then((response) => {
        this.fillFields(response['data']['data'])
      })
  },
  methods: {
    fillFields(receivedData) {
      if (receivedData.length != 0) {
        console.log(receivedData)
        var userData = receivedData[0]

        this.inputEmail = userData['email']
        this.inputBirthdate = userData['birthdate']
        this.inputGender = userData['gender']
        this.userInfosLoading = false
      }
      else {
        this.$router.push('/')
      }
    },
    updateUserInfos() {
      if (!this.inputEmail) {
        alert("Email manquant")
        return
      }
      if (!this.checkEmail(this.inputEmail)) {
        alert("Email incorrect")
        return
      }

      const UserUpdatedInfos = {
        email: this.inputEmail,
        birthdate: this.inputBirthdate,
        gender: this.inputGender,
        user_unique_code: this.$store.state.connectedUserUniqueCode
      };

      axios
        .post('https://we-are-pirates.herokuapp.com' + '/admin/update_user_infos', UserUpdatedInfos)
        .then(() => {
          // response["data"]
          alert("Vos informations ont bien été mises à jour.")
        })
    },
    updateUserPassword() {
      if (!this.inputPassword) {
        alert("Mot de passe manquant")
        return
      }
      if (this.inputConfirmPassword !== this.inputPassword) {
        alert("Les mots de passes ne sont pas identiques.")
        return
      }

      const UserUpdatedPassword = {
        password: this.inputPassword,
        user_unique_code: this.$store.state.connectedUserUniqueCode
      };

      axios
        .post('https://we-are-pirates.herokuapp.com' + '/admin/update_user_password', UserUpdatedPassword)
        .then(() => {
          // response["data"]
          alert("Votre mot de passe a bien été mis à jour.")
        })

    },
    checkEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    signUp() {
      if (!this.checkEmail(this.inputEmail)) {
        alert("Email incorrect")
        return
      }
      if (!this.inputEmail || !this.inputPassword) {
        alert("Email ou mot de passe manquant")
        return
      }
      if (this.inputConfirmPassword !== this.inputPassword) {
        alert("Les mots de passes ne sont pas identiques")
        return
      }

      const connectionLogs = {
        email: this.inputEmail,
        password: this.inputPassword,
        birthdate: this.inputBirthdate,
        gender: this.inputGender,
      };
      console.log("connexion :")
      console.log('email : ' + connectionLogs['email']);
      console.log('password : ' + connectionLogs['password'] + '\n');
      axios
        .post('https://we-are-pirates.herokuapp.com' + "/user/sign_up", connectionLogs)
        .then((response) => {
          if (response["data"] == "email") {
            alert("Email déjà utilisé")
          }
          else if (response["data"]) {
            console.log("toto");
            console.log(response["data"]);
            this.$store.state.fetch.addGlobalHeader('x-wap-token',response["data"].unique_code);
            this.$store.state.connectedUserUniqueCode = response["data"].unique_code;
            this.$store.state.connectedUser = response["data"];
            this.isConnected = true;
            document.cookie = "uniquecode=" + response["data"].unique_code;
          }
          else {
            alert("Erreur lors de la création du compte")
          }
        })
        .catch((response) => {
          console.log(response);
          console.log("catch");
          this.isConnected = false;
        });
    }
  },
}
</script>

<style scoped>
.font-weight-600 {
  font-weight: 600;
}

input,
select {
  max-width: 320px;
}

.change-password-container>div {
  width: 320px;
  display: inline-block;
}
</style>