<template>
  <div class="profile-layout">
    <div class="profil-layout-container">
      <div class="d-none d-lg-block">
        <h2 class="title-police">Mon compte</h2>
        <div class="mt-4">
          <span class="profile-menu" v-for="(availablePage, index) in this.availablePages"
            v-bind:key="'available-page-' + index" v-bind:class="this.selectedProfileMenuClass(index)"
            v-on:click="this.currentPage = index">
            {{ availablePage }}
          </span>
        </div>
      </div>
      <div class="d-block d-lg-none">
        <select class="form-select" aria-label="Default select example" v-model="this.currentPage">
          <option v-for="(availableMobilePage, index) in this.availablePages"
            v-bind:key="'available-mobile-page-' + index" v-on:click="this.currentPage = index" v-bind:value="index">
            {{ availableMobilePage }}
          </option>
        </select>
      </div>
      <div v-if="this.currentPage == 0">
        <PersonnalInfos></PersonnalInfos>
        <!-- Infos personnelles -->
      </div>
      <div v-if="this.currentPage == 1">
        <SavedFlags></SavedFlags>
      </div>
      <!-- <div v-if="this.currentPage == 2">
        Parrainage
      </div> -->
    </div>
  </div>
</template>

<script>
import PersonnalInfos from './PersonnalInfos.vue';
import SavedFlags from './SavedFlags.vue';


export default {
  name: "ProfileLayout",
  data() {
    return {
      currentPage: 1,
      availablePages: ["Mes infos personnelles", "Ma galerie"]
    };
  },
  components: { SavedFlags, PersonnalInfos },
  methods: {
    selectedProfileMenuClass(index) {
      if (index == this.currentPage)
        return ("current");
      return ("");
    }
  },
};
</script>

<style scoped>
.profil-layout-container>.title-police {
  margin-bottom: 35px;
  font-size: 45px;
}

.profile-layout {
  margin: 56px;
}

.profil-layout-container {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 16px;
  max-width: 1616px;
  /* margin: 56px; */
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}

.profile-menu {
  margin-right: 50px;
  padding: 10px;
  font-size: 22px;
  cursor: pointer;
}

.profile-menu.current {
  border-bottom: 5px solid #DDBD3C;
  font-weight: 600;
}

option:hover {
  background-color: yellow;
}

@media screen and (max-width: 991px) {
  .profil-layout-container {
    background-color: transparent;
    padding: 0;
  }

  .profile-layout {
    margin: 28px;
  }
}
</style>