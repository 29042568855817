import { createRouter, createWebHashHistory } from "vue-router";
import FlagCreationView from "../views/FlagCreationView.vue";
import SaveFlagView from "../views/SaveFlagView.vue";
import AdminView from "../views/AdminView.vue";
import ProfileView from "../views/ProfileView.vue";
import HomePageView from "../views/HomePageView.vue";
import AboutView from "../views/AboutView.vue";
import FAQView from "../views/FAQView.vue";
import checkout from "../views/CheckoutView.vue"
import CreditView from "../views/CreditView.vue"
import FlagView from "../views/FlagView.vue"
import store from '../store/index.js';

const routes = [
  {
    path: "/checkout",
    name: "Paiement",
    component: checkout,
  },
  {
    path: "/flag-creator",
    name: "Création de drapeau",
    component: FlagCreationView,
  },
  {
    path: "/save-flag",
    name: "Sauvegarde du drapeau",
    component: SaveFlagView,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
  },
  {
    path: "/profile",
    name: "Profil",
    component: ProfileView,
  },
  {
    path: "/credit",
    name: "Crédit",
    component: CreditView,
    beforeEnter: async (to, from, next) => {
      await store.dispatch('checkSession');
      const isConnected = store.getters['isConnected'];
      if (isConnected) {
        next();
      } else {
        next('/');
      }
    }
  },  
  {
    path: "/about",
    name: "À propos",
    component: AboutView,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQView,
  },
  {
    path: "/",
    name: "Page d'accueil",
    component: HomePageView,
  },
  {
    path: "/flag/:id",
    name: "Flag",
    component: FlagView,
  },  
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
