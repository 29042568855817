import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { createVfm } from 'vue-final-modal'
import 'vue-final-modal/style.css'
import { createI18n } from 'vue-i18n';
import  './assets/styles/app.scss';
import VueSocialSharing from 'vue-social-sharing'
import store from './store/index.js';

// 2. Create VueI18n instance with options
const i18n = new createI18n({
  // set locale
  locale: 'fr',
  // set locale messages
  messages: {
    fr: {
      message: {
        welcome: 'Bienvenue sur We Are Pirates',
        welcome_msg: "Si tu veux devenir un vrai pirate, t'es au bon endroit. Ici, tu crées ton propre Jolly Roger, ton drapeau pirate à ton image. Télécharge-le en qualité 72 DPI pour le montrer fièrement en PP ou en fond d'écran, ou choisis la qualité 300 DPI si tu veux l'imprimer et l'afficher IRL². Explore toutes nos options, fais travailler ton imagination, et rejoins notre équipe en criant haut et fort : WE ARE PIRATES ! ⚓🏴‍☠️😎"
        // welcome_msg: "Bienvenu à toi moussaillon !<br><br>Je t’appelle ainsi car tu n’es pas encore un vrai pirate ! Pour devenir un vrai boucanier, il te faut ton propre Jolly Roger, mais c’est quoi un Jolly Roger ?<br><br>Un Jolly Roger est un pavillon noir, un drapeau pirate utilisé pour inspirer la peur au moment d’attaquer, de passer à l’abordage ! Le drapeau pirate est aussi un symbole qui impose le respect ! Il est souvent orné d’une tête de mort surmontant deux tibias entrecroisés ou deux sabres croisés, mais grâce à We Are Pirates tu vas pouvoir aller bien plus loin et réaliser en ligne et facilement ton propre drapeau pirates personnalisé pour représenter comme il se doit tes couleurs ou celles de ton équipage !<br><br>Une fois que tu as créé ton drapeau pirate idéal, tu peux télécharger le fichier ou l'imprimer sur un support physique, comme un drapeau, un t-shirt, une coque de téléphone, un mug etc... Bien sûr une qualité d'impression optimale est garantie pour que ton drapeau pirate personnalisé soit aussi impressionnant en physique qu’en digital !<br><br>Alors n'attends plus pour créer ton drapeau pirate personnalisé. Navigue sur ce site pour découvrir toutes les options disponibles et laisse ton imagination déferler.<br><br>Et quand tu auras enfin ton propre drapeau, ton équipage et toi pourrez le regarder fièrement en disant WE ARE PIRATES !"
      }
    },
    en: {
      message: {
        welcome: 'Welcome to',
        welcome_msg: "Welcome, cabin boy ! That’s how I’ll be calling you for now as you are not a real pirate yet !<BR><BR>To become a real buccaneer you need to have your own Jolly Roger. But what is a Jolly Roger ? A Jolly Roger is a black pirate flag used to frighten when attacking the vessel just before boarding. A pirate flag is also a symbol that commands respect. It is often embellished with a skull and crossbones or crossed sabres but, thanks to WE ARE PIRATES, you can imagine much more and easily create online your own personalized pirate flag which will properly represent your own colors or those of your crew.<BR><BR>Once you have created your ideal pirate flag, you can download the file or print it out on a physical support such as a flag, a T-shirt, a phone-case, a mug etc…. Optimal printing quality is guaranteed so that your personalized pirate flag is just as impressive digitally as physically.<BR><BR>So why wait any longer to create your own personalized pirate flag ?<BR><BR>Surf on this site to discover all the options available to you and let your imagination roll !!<BR><BR>And when finally you have your own pirate flag, you and your crew will be able to look at it with pride and say WE ARE PIRATES !"
      }
    }
  }
});

var app = createApp(App)

app.config.productionTip = false;

const vfm = createVfm()

app.use(vfm)
app.use(store)
app.use(router)
app.use(i18n)
app.use(VueSocialSharing);

app.mount("#app");