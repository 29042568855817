<template>
  <div class="admin-view">
    <h1>ADMIN</h1>
    <router-link to="/">Accueil</router-link>
    <p style="color: white; font-size: 20px;">
      {{ this.unknownElementsList }}
      <br>
      <br>
      nombre d'éléments manquants détectés : {{ this.unknownElementsList.length }}

    </p>
    <button style="color: red; font-size: 20px;" v-on:click="this.synchronizeFlagElements">Synchroniser</button>
    <div class="ml-auto mb-5" style="width: 90%; margin-left: auto; margin-right: auto">
      <h2>Nouvel élément de drapeau</h2>
      <SingleFlagElement v-bind:is-new-element="true"></SingleFlagElement>
    </div>
    <div class="element-categories">
      <h2 class="text-center color-white">Elements existants</h2>
      <div style="text-align: left" v-for="(flagElementCategory, index) in Object.keys(this.flagElements)"
        v-bind:key="'flag-element-category' + index">
        <h3>{{ flagElementCategory.toUpperCase() }}</h3>
        <div class="px-3 d-inline-block" v-for="(flagElement, index) in this.flagElements[flagElementCategory]"
          v-bind:key="'flagElement' + index">
          {{ flagElement['image_url'] }}
          <SingleFlagElement v-bind:element-name="flagElement['name']"
            v-bind:element-image-file-name="flagElement['image_url']" v-bind:element-type="flagElement['type']" v-bind:element-premium="flagElement['premium']"
            v-bind:elementId="flagElement['id']"></SingleFlagElement>
        </div>
        <br>
      </div>
    </div>



    <!-- <div class="px-3 d-inline-block" v-for="(flagElement, index) in this.info" v-bind:key="'flagElement' + index">
      <SingleFlagElement v-bind:element-name="flagElement['name']" v-bind:element-image-file-name="flagElement['image_url']" v-bind:element-type="flagElement['type']" v-bind:element-premium="flagElement['premium']" v-bind:elementId="flagElement['id']"></SingleFlagElement>
    </div> -->

  </div>
</template>

<script>
import SingleFlagElement from '@/components/admin/SingleFlagElement.vue';
import axios from 'axios'


export default {
  name: "AdminView",
  data() {
    return {
      info: null,
      flagElements: {},
      detectedItems: [],
      unknownElementsList: []
    }
  },
  components: {
    SingleFlagElement
  },
  beforeCreate() {
    if (this.$store.state.connectedUserUniqueCode != "VrfgkLeImN4wS")
      this.$router.push('/')
  },
  mounted() {
    this.detectedItems = require.context(
      "/public/flag-elements-img",
      true,
      /^.*\.svg$/
    ).keys()
    // console.log("detectedItems")
    // console.log(this.detectedItems[0])
    axios
      .get('https://we-are-pirates.herokuapp.com/admin')
      .then((response) => {
        this.info = response['data'].data
        this.flagElements = this.categorizeElements(this.info)
        console.log(this.flagElements)
        this.setUnknownElementsList()
      })
  },
  methods: {
    synchronizeFlagElements() {
      console.log('synchronizeFlagElements')
      console.log(this.unknownElementsList[0])
      for (var index in this.unknownElementsList)
        this.synchronizesingleFlagElement(this.unknownElementsList[index])
    },
    synchronizesingleFlagElement(missingElement) {
      var name = missingElement.split("/")[missingElement.split("/").length - 1]
      const singleElement = {
        name: name,
        "image_url": missingElement.slice(2),
        "type": this.getCategoryFromImgPath(missingElement),
      };

      console.log('ajout')
      console.log(singleElement)
      axios
        .post('https://we-are-pirates.herokuapp.com/admin/create', singleElement)
        .then(() => {
          // this.info = response['data'].data
          // console.log(this.info[0])
          console.log("done create")
          // location.reload()
        })
    },
    setUnknownElementsList() {
      // console.log('unknownElementsList')
      if (this.flagElements == {})
        return []

      for (var detectedItemIndex in this.detectedItems) {
        // console.log(this.detectedItems[detectedItemIndex])
        if (this.doesElementAlreadyExist(this.detectedItems[detectedItemIndex]) == false) {
          this.unknownElementsList.push(this.detectedItems[detectedItemIndex])
          // console.log("DOEST EXIST")
        }
        else {
          // console.log("EXIST")
        }

        // console.log(this.detectedItems[detectedItemIndex]);

      }
      return (this.unknownElementsList)
    },
    doesElementAlreadyExist(element) {
      // console.log("loop")
      // console.log(element);

      var category = this.getCategoryFromImgPath(element)
      // var category = 

      // console.log(this.getCategoryFromImgPath(element));
      // console.log("search")
      for (var knownItemIndex in this.flagElements[category]) {
        // console.log('compare')
        // console.log('./' + this.flagElements[category][knownItemIndex]['image_url'])
        // console.log(element)
        if ('./' + this.flagElements[category][knownItemIndex]['image_url'] == element) {
          return (true)
        }
      }
      return (false)

    },
    getCategoryFromImgPath(imgPath) {
      var folderName = imgPath.split("/")[1]

      // console.log(folderName)
      // console.log(fileName)
      // console.log(bonus)
      if (folderName == "17 CORNES - HORNS") {
        if (imgPath.split("/")[3].includes('LEFT'))
          return ("17 CORNES - HORNS LEFT")
        return ("17 CORNES - HORNS RIGHT")
      }
      if (folderName == "08 ORBITES - ORBITS") {
        if (imgPath.split("/")[3].includes('LEFT'))
          return ("08 ORBITES - ORBITS LEFT")
        return ("08 ORBITES - ORBITS RIGHT")
      }
      if (folderName == "09 YEUX - EYES") {
        if (imgPath.split("/")[3].includes('LEFT'))
          return ("09 YEUX - EYES LEFT")
        return ("09 YEUX - EYES RIGHT")
      }
      if (folderName == "03 CROIX - CROSS") {
        if (imgPath.includes('H.svg'))
          return ("03 CROIX - CROSS H")
        if (imgPath.includes('V.svg'))
          return ("03 CROIX - CROSS V")
        if (imgPath.includes('LEFT'))
          return ("03 CROIX - CROSS LEFT")
        return ("03 CROIX - CROSS RIGHT")

      }
      if (folderName == "07 DENTS - TOOTH") {
        if (imgPath.includes('TOP'))
          return ("07 DENTS - TOOTH TOP")
        return ("07 DENTS - TOOTH BOTTOM")
      }
      return (imgPath.split("/")[1])
    },
    categorizeElements(data) {
      var result = {}

      data.forEach(flagElement => {
        if (typeof result[flagElement['type']] === 'undefined')
          result[flagElement['type']] = []
        result[flagElement['type']].push(flagElement)
      });
      return (result)
    }
  },
};
</script>

<style scoped>
.element-categories {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}

.element-categories>div {
  border-top: 1px solid black;
  margin-top: 60px;
}
</style>
