<template>
    <h2 class="mb-5">Acheter des crédits
      <button class="btn" v-if="btnClose" @click="hideStripe">Fermer</button>
    </h2>
    <p>Ayez en tête, matelot, que pour orner votre pavillon de l'artefact le plus distingué, point d'omission dans l'achat de crédits avant d'hisser les couleurs !</p>
    <span v-if="showStripe == 0">
    <div class="pack-credit-list">

      <div class="pack-credit-item" v-on:click="this.selectPack(1)" :class="{ 'selected': nb === 1 }">
        <h3>Pack Marin d'eau douce</h3>
        <div class="pack-nb-credit"> 1 credit </div>
        <div class="pack-price">0.99€</div>
      </div>
      <div class="pack-credit-item" v-on:click="this.selectPack(2)" :class="{ 'selected': nb === 2 }">
        <h3>Pack Apprenti Pirate</h3>
        <div class="pack-nb-credit"> 2 credits </div>
        <div class="pack-price">1.98€</div>
      </div>
      <div class="pack-credit-item" v-on:click="this.selectPack(5)" :class="{ 'selected': nb === 5 }">
      <div class="pack-discount">-20%</div>
        <h3>Pack Boucanier</h3>
        <div class="pack-nb-credit"> 5 credits </div>
        <div class="pack-price">3.99€</div>
      </div>
      <div class="pack-credit-item pack-credit-best-choice" v-on:click="this.selectPack(10)" :class="{ 'selected': nb === 10 }">
      <div class="pack-discount">-30%</div>
        <h3>Pack Seigneur des Océans</h3>
        <div class="pack-nb-credit"> 10 credits </div>
        <div class="pack-price">6.99€</div>
      </div>
      <div class="pack-credit-item" v-on:click="this.selectPack(20)" :class="{ 'selected': nb === 20 }">
      <div class="pack-discount">-50%</div>
        <h3>Pack Roi des Pirates</h3>
        <div class="pack-nb-credit"> 20 credits </div>
        <div class="pack-price">9.99€</div>
      </div>
     </div>
     <input :disabled="lockSubmit" class="btn btn-buy btn-primary shadow-sm" type="submit" value="Acheter mon pack" v-on:click.prevent="purchase" />
    </span>
     <div ref="card">
          <!-- A Stripe Element will be inserted here. -->
        </div>
        <span v-if="showStripe == 1">
          <span v-if="modal && waitingStripe == 1">
            <div id="wait-checkout">Traitement....</div>
          </span>
            <div id="checkout">
            </div>
            
        </span>
    </template>
    
<script>
 /* global Stripe */

  import axios from 'axios'
  export default {
    name: "CreditBuy",
    data () {
    return {
      spk:"pk_test_51NH17OLkZ9uj4uhGWepaoIaUfrZ2JFzYGYEAVINdzvSu7OdxrH80SYSrMhfHsDnVgOGEnqFswZiseaw10kZ5hoDa00HYPOWR1Y",
      stripe:undefined,
      mounted: false,
      card:undefined,
      msg: 'Donate Here',
      payAmount:"$10.00",
      showStripe: 0,
      waitingStripe:0,
      nb:'',
      lockSubmit:false,
      btnClose: false,
      checkout: false
    }
},

    props: {
      closeModal: {
        type: Function
      },
      modal: Boolean
    },
    
  beforeUnmount() {
  if(this.checkout)
    this.checkout.destroy()
  },
  methods:{
    go(){
      var self=this;
      self.stripe= Stripe(self.spk);    
    },
    hideStripe(){
      this.showStripe = 0;
    },
    async purchase() {
      if(this.nb < 1){
        alert('Merci de sélectionner votre pack')
        return;
      }
        this.waitingStripe = 1;
        this.showStripe = 1;
        const session = await this.$store.state.fetch.post('/stripe/create-checkout-session',{body:{credit: this.nb}});
        let clientSecret = session.clientSecret;

        this.checkout = await this.stripe.initEmbeddedCheckout({
          clientSecret,
          onComplete: this.handleComplete
        });
        // Mount Checkout
        this.checkout.mount('#checkout');
    },
    async handleComplete(){
      this.waitingStripe = 0;
        this.btnClose = true;
        let nbCredit = this.$store.state.connectedUser.credits + this.nb;
        this.$store.state.connectedUser.credits += nbCredit;
        console.log('AAAAAAAAAAAAAAAAAAAAAA')
        this.$store.commit('SET_CREDITS',nbCredit)
    },

    async processTransaction(transactionToken){
      //var self=this;
      let dt= {
          amount:200,
          currency:"USD",
          description:"something to say",
          token:transactionToken
      }
      const payment = await axios.post('https://we-are-pirates.herokuapp.com' + '/stripe/charge', dt);
      console.log(payment);
    },
    stripCurrency(val){
      return val.replace(',','').relpace('$','').replace('.','')
    },
    selectPack(nb) {
        this.nb = nb;
        if(!this.mounted){
          this.go();
        }
    },
    onSuccess() {
      console.log('Payment successful!');
    },
    onError() {
      console.error('Payment failed!');
    },
  }
}
</script>
<style>
</style>