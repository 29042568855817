export default class fetch {
    
    constructor() {
        this.globalHeaders = [];
    }
    
    addGlobalHeader(key, value) {
        console.log('ON AJOUTE',key,value)
        let index = Object.keys(this.globalHeaders).find(headerIndex => this.globalHeaders[headerIndex].key == key)
        if (typeof index !== "undefined") {
            this.globalHeaders[index] = { key, value};
        } else {
            this.globalHeaders.push({ key, value})
        }


    }
    
    async run(uri, params) {     
        console.log('RUNNNNNNN',this.globalHeaders)   
        if (!params.headers)
            params.headers = new window.Headers();
        for(var i in this.globalHeaders) {
            params.headers.append(this.globalHeaders[i].key, this.globalHeaders[i].value);
        }
        params.headers.append('Content-Type', 'application/json');
        const response = await window.fetch( 'https://we-are-pirates.herokuapp.com' + uri, params);
        let res = await response.json();
        return res;
    }
    
    async get(uri, params = {}) {
        params.method = 'GET'
        return await this.run(uri, params);
    }
    
    async post(uri, params = {}) {
        params.method = 'POST';
        params.body = JSON.stringify(params.body);
        return await this.run(uri, params);
    }
    
    async put(uri, params = {}) {
        params.method = 'PUT';
        params.body = JSON.stringify(params.body);
        return await this.run(uri, params);
    }
    
    async delete(uri, params = {}) {
        params.method = 'DELETE';
        return await this.run(uri, params);
    }
    
    
}