<template>
    <div class="w-100 h-100 position-relative" @mouseover="this.mouseOverEffect()" @mousedown="this.displayHover = true"
        @mouseleave="this.displayHover = false">
        <!-- {{ this.windowWidth }} -->
        <FlagPreview v-bind:flag-elements="this.flag.flag_elements">

        </FlagPreview>
        <!-- <div id='drawing'>
            <img v-if="this.flag.flag_elements['cross-back'] !== ''" v-bind:src="this.publicPath + this.flag.flag_elements['cross-back']['image_url']">
            <img v-if="this.flag.flag_elements['upper-skull'] !== ''" v-bind:src="this.publicPath + this.flag.flag_elements['upper-skull']['image_url']">
            <img v-if="this.flag.flag_elements['jaw'] !== ''" v-bind:src="this.publicPath + this.flag.flag_elements['jaw']['image_url']">
            <img v-if="this.flag.flag_elements['eyes'] !== ''" v-bind:src="this.publicPath + this.flag.flag_elements['eyes']['image_url']">
        </div> -->

        <div v-if="this.displayHover">
            <div class="hover-flag position-absolute w-100 h-100 top-0">
                <!-- <p class="mt-3">
                    {{ this.flag.flag_name }}
                </p> -->
                <!--div class="text-center">
                    <button class="connexion-button yellow-button" v-on:click="this.shareFlag()">Partager</button>
                </div-->
                <div class="text-center">
                    <button class="connexion-button yellow-button" v-on:click="this.loadSavedFlag()">Modifier</button>
                </div>
                <div class="text-center">
                    <button class="connexion-button yellow-button" v-on:click="this.$emit('downloadImage', this.flag.flag_name, this.flag.flag_elements)">Télécharger</button>
                </div>
                <div class="text-center">
                    <button class="connexion-button yellow-button" v-on:click="this.deleteSavedFlag()">Supprimer</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import FlagPreview from '../homePage/FlagPreview.vue';

export default {
    name: "SavedFlag",
    data() {
        return {
            publicPath: process.env.BASE_URL + "flag-elements-img/",
            displayHover: false
        };
    },
    props: {
        flag: {
            type: Object,
            required: true
        },
        deleteFlagMethod: {
            type: Function,
            required: true
        }
    },
    computed: {
        windowWidth() {
            return (window.innerWidth)
        }
    },
    methods: {
        mouseOverEffect() {
            if (this.windowWidth < 1200)
                return
            this.displayHover = true
        },
        deleteSavedFlag() {
            this.deleteFlagMethod(this.flag.id);
            axios
                .post('https://we-are-pirates.herokuapp.com' + "/admin/delete_saved_flag", { id: this.flag.id })
                .then(() => {
                    // this.info = response['data'].data
                    console.log("Drapeau supprimé");
                    // this.deleteFlagMethod(this.flag.id)
                    // location.reload()
                });
        },
        loadSavedFlag() {
            if (this.displayHover == false)
                return
            // console.log("avant : " + this.$store.state.chosenFlagElements);
            this.$store.commit("loadSavedFlag", this.flag.flag_elements);
            this.$store.state.loadedFlagName = this.flag.flag_name
            // console.log("après : " + this.$store.state.chosenFlagElements);
            this.$router.push("/flag-creator");
        },
        shareFlag(){
            this.$router.push("/flag/"+this.flag.id);
        }
    },
    components: { FlagPreview }
};
</script>

<style scoped>
#drawing {
    position: relative;
    width: 250px;
    height: 250px;
    /* border: solid 1px black; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-left: auto;
    margin-right: auto;
}

#drawing img {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
}

.hover-flag {
    background-color: rgba(255, 255, 255, 0.5);
}

.yellow-button {
    padding: 6px 12px;
    margin-top: 13px;
}

@media screen and (max-width: 991px) {
    .yellow-button {
        margin-top: 5px;
    }
}
</style>