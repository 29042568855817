<template>
    <div>
        <div class="mt-5">
            <canvas id="finalDrawing" hidden></canvas>

            <div class="text-center" v-if="this.savedFlagsLoading == true">
                <div class="spinner-border mt-4" role="status" />
            </div>
            <div v-else-if="this.savedFlags.length">
                <h5>Mes drapeaux sauvegardés (Basse qualité)</h5>
                <p class="mb-2">
                    Vous pouvez modifier, supprimer et re-téléchargés en basse qualité tous vos drapeaux sauvegardés ou
                    achetés.
                </p>
                <div class="d-flex saved-flags-container">
                    <div class="flag-container" v-for="(savedFlag, index) in this.nonPremiumFlags"
                        v-bind:key="'saved-flag-' + index">
                        <SavedFlag @downloadImage="this.downloadFinalImage" v-bind:flag="savedFlag"
                            v-bind:deleteFlagMethod="this.removeSavedFlag" />
                    </div>
                    <!-- <div class="single-saved-flag" v-for="(savedFlag, index) in this.savedFlags" v-bind:key="'saved-flag-' + index">
                <SavedFlag v-bind:flag="savedFlag" v-bind:deleteFlagMethod="this.removeSavedFlag"/>
            </div> -->
                </div>

                TODO: ne plus cacher drapeaux premium quand la fonctionnalité existera !
                <!-- <div class="premium-flags">
                    <h5 class="mt-4">Mes drapeaux achetés (Haute qualité)</h5>
                    <p class="mb-2">
                        Vous pouvez re-téléchargés en haute qualité tous vos drapeaux achetés.
                    </p>
                    <div class="d-flex saved-flags-container">
                        <div class="flag-container" v-for="(savedFlag, index) in this.premiumFlags"
                            v-bind:key="'saved-flag-' + index">
                            <SavedFlag v-bind:flag="savedFlag" v-bind:deleteFlagMethod="this.removeSavedFlag" />
                        </div>
                    </div>
                </div> -->
            </div>
            <div v-else>
                Aucun drapeau trouvé.
                <br>
                Crée ton propre drapeau
                <router-link class="text-decoration-underline fw-bold" style="color: black" to="/flag-creator">ici</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import SavedFlag from './SavedFlag.vue';
import Utils from '../../utils/utils.js';
export default {
    name: "SavedFlags",
    components: {
        SavedFlag
    },
    data() {
        return {
            savedFlags: [],
            savedFlagsLoading: true,
            publicPath: process.env.BASE_URL + "flag-elements-img/",
            canvas: null,
            ctx: null
        }
    },
    mounted() {
        var uniqueCode = this.$store.state.connectedUserUniqueCode

        if (uniqueCode) {
            axios
                .get('https://we-are-pirates.herokuapp.com' + "/user/is_connected/" + uniqueCode)
                .then((response) => {
                    if (response["data"] !== "no") {
                        // this.$store.state.connectedUserId = response["data"];
                        axios
                            .get('https://we-are-pirates.herokuapp.com' + '/admin/get_user_saved_flags/' + uniqueCode)
                            .then((response) => {
                                for (const savedFlag in response['data']['data']) {
                                    var tmp = response['data']['data'][savedFlag]
                                    tmp.flag_elements = JSON.parse(tmp.flag_elements)
                                    // console.log(tmp.flag_elements)
                                    this.savedFlags.push(tmp)
                                }
                                this.savedFlagsLoading = false
                                // console.log(this.savedFlags)
                            })
                    }
                    else {
                        console.log("reset cookies");
                        this.deleteAllCookies();
                    }
                });
            const canvas = document.querySelector("#finalDrawing");
            canvas.width = 800;
            canvas.height = 500;
            const ctx = canvas.getContext("2d");
            this.ctx = ctx;
            this.canvas = canvas;
        }
        else {
            console.log("ProBLEM")
            this.$router.push("/")
        }
    },
    computed: {
        premiumFlags() {
            return (this.filterFlags(true))
        },
        nonPremiumFlags() {
            return (this.filterFlags(false))
        }
    },
    methods: {
        filterFlags(premium) {
            return (this.savedFlags.filter(flag => flag['premium_version_payed'] == premium))
        },
        removeSavedFlag(savedFlagId) {

            // console.log('suppression ' + savedFlagId + this.savedFlags[5].id)
            this.savedFlags.forEach((savedFlag, index) => {
                console.log(savedFlag)
                if (savedFlag.id == savedFlagId)
                    this.savedFlags.splice(index, 1)
            });

        },
        downloadFinalImage(flagName, flagElements) {
            console.log("downloadFinalImage")
            console.log(flagName)
            console.log(flagElements)
            //this.drawFinalImage(flagElements)
            Utils.drawFinalImage(flagElements, this.canvas);
            var image = this.canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
            var link = document.createElement('a');
            link.download = flagName + ".png";
            link.href = image;
            link.click();
        },
        /*drawFinalImage(flagElements) {
            const context = this.canvas.getContext('2d');

            context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            for (var singleElement in flagElements) {
                if (flagElements[singleElement]['image_url']) {
                    console.log(this.publicPath + flagElements[singleElement]['image_url'])
                    this.drawSingleImage(this.publicPath + flagElements[singleElement]['image_url'])
                }
            }
        },
        drawSingleImage(src) {
            var singleImage = new Image();
            singleImage.src = src
            this.ctx.drawImage(singleImage, 0, 0, 800, 500);
        },*/
    },
};
</script>

<style scoped>
h5 {
    font-weight: 600 !important;
}

.saved-flags-container {
    flex-wrap: wrap;
}

.single-saved-flag {
    width: 300px;
    border: 1px black solid;
    padding-top: 30px;
}

.flag-container {
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    background-color: black;
    width: 275px;
    height: 171px;
    margin: 10px;
    display: inline-block;
}

@media screen and (max-width: 991px) {
    .flag-container {
        width: 229px;
        height: 142px;
    }
}
</style>