<template>
  <span class="flag-element-preview" v-bind:class="this.zoomClass">
    <div class="selectable-option position-relative" v-bind:class="this.selectedOptionClass"
      v-on:click="this.$emit('selected')">
      <div class="position-absolute">
        <img v-for="(previewImg, index) in this.imageUrl" class="option-img" v-bind:key="'preview-img-' + index"
          v-bind:src="this.publicPath + previewImg" />
        <!-- <img class="option-img" v-bind:src="this.imageUrl"> -->
      </div>
      <!-- {{ option['image_url'].split("\\")[1] }} -->
    </div>
  </span>
</template>

<script>
export default {
  name: "FlagElementPreview",
  data() {
    return {
      // publicPath: process.env.BASE_URL + "flag-elements-img/",
    };
  },
  props: {
    imageUrl: {
      type: Array,
      required: true,
    },
    isChosen: {
      type: Boolean,
      required: false,
      default: false,
    },
    zoom: {
      type: Number,
      required: false,
      default: 0,
    },
    isPremium: {
      type: Boolean,
      required: false,
      default: false,
    },
    pathFromRoot: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowFreeIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    publicPath() {
      return (process.env.BASE_URL + (this.pathFromRoot ? "" : "flag-elements-img/"))
    },
    zoomClass() {
      if (this.zoom == 1) return "zoomed-one";
      else if (this.zoom == 2) return "zoomed-two";
      else if (this.zoom == 3) return "zoomed-three";
      else if (this.zoom == -1) return "zoomed-menus-one"
      else if (this.zoom == -2) return "zoomed-menus-two"

      return "";
    },
    selectedOptionClass() {
      let optionClass = "eee";
      if (this.isChosen) optionClass = "chosen";
      if (this.isPremium) optionClass += " premium-item";
      else if (this.isShowFreeIcon)
        optionClass += " free-item";
      return optionClass;
    },
  },
};
</script>

<style scoped>
.flag-element-preview {
  cursor: pointer;
}

.selectable-option {
  width: 109px;
  height: 109px;
  background-color: white;
  display: inline-block;
  border-radius: 14px;
  margin: 10px 8px;
  overflow: hidden;
}

.option-img {
  /* width: 110px; */
  height: 110px;
  left: -34px;
  position: absolute;
}

.premium-item::before {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  background: url('../assets/lock-icon.png');
  background-size: cover;
  width: 20px;
  height: 20px;
  z-index: 99;
}

.free-item::before {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  background: url('../assets/unlock-icon.png');
  background-size: cover;
  width: 20px;
  height: 20px;
  z-index: 99;
}

.chosen .option-img {
  left: -40px;
  top: -6px;
}

.chosen {
  border: 6px solid #3361ae;
  /* outline: 6px solid #DDBD3C; */
  box-shadow: 0px 4px 4px 0px #00000040;

  /* padding-left: -10px */
}

.zoomed-three .option-img {
  transform: scale(2.5);
}

.zoomed-menus-one .option-img {
  transform: scale(0.9);
}

.zoomed-menus-two .option-img {
  transform: scale(0.7);
}

.zoomed-one .option-img {
  height: 144px;
  left: -61px;
  top: -17px;
}

.zoomed-one .chosen .option-img {
  left: -67px;
  top: -23px;
}

.zoomed-two .option-img {
  height: 178px;
  left: -88px;
  top: -34px;
}

.zoomed-two .chosen .option-img {
  left: -94px;
  top: -40px;
}

@media screen and (max-width: 991px) {
  .selectable-option {
    width: 76px;
    height: 76px;
  }

  .option-img {
    height: 76px;
    left: -22px;
  }

  .chosen .option-img {
    left: -28px;
  }

  .zoomed-one .option-img {
    height: 110px;
    left: -50px;
    top: -17px;
  }

  .zoomed-one .chosen .option-img {
    left: -56px;
    top: -23px;
  }

  .zoomed-two .option-img {
    height: 144px;
    left: -78px;
    top: -34px;
  }

  .zoomed-two .chosen .option-img {
    left: -84px;
    top: -40px;
  }
}
</style>