
<template>
  <VueFinalModal class="flex justify-center items-center modal-sign-in"
    content-class="flex flex-col max-w-xl m-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2">
    <h2 class="mb-4 title-police">Connexion</h2>
    E-mail
    <input type="text" class="form-control mb-4 mt-2" placeholder="Email" aria-label="email" v-model="inputEmail">
    Mot de passe
    <input type="text" class="form-control mt-2" placeholder="Mot de passe" aria-label="email" v-model="inputPassword">

    <div class="w-100" style="text-align: right">
      <button class="border-0 bg-white mb-3" style="font-weight: 600; text-decoration: underline; color: black">
        J’ai oublié mon mot de passe (à implémenter)
      </button>
      <div>
        <button class="btn btn-primary connexion-button white-button" style="margin-right: 16px; font-weight: 600;"
          v-on:click="this.openSignUp()">
          S’inscrire
        </button>
        <button class="btn btn-primary connexion-button yellow-button" style="font-weight: 600;"
          v-on:click="this.connexion">
          Se connecter
        </button>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
import axios from 'axios'
import { VueFinalModal } from 'vue-final-modal'

export default {
  name: "SignInModal",
  components: { VueFinalModal },
  data() {
    return {
      inputEmail: "email@toto.com",
      inputPassword: "SuperPassword"
    }
  },
  props: {
    closeModal: {
      type: Function
    },
    openSignUp: {
      type: Function
    }
  },
  methods: {
    connexion() {
      if (!this.inputEmail || !this.inputPassword) {
        alert("Email ou mot de passe manquant")
        return
      }
      const connectionLogs = {
        email: this.inputEmail,
        password: this.inputPassword
      };
      console.log("connexion :")
      console.log('email : ' + connectionLogs['email']);
      console.log('password : ' + connectionLogs['password'] + '\n');
      console.log(process.env)
      axios
        .post('https://we-are-pirates.herokuapp.com' + "/user/authentication", connectionLogs)
        .then((response) => {
          if (response["data"]) {
            this.$store.state.fetch.addGlobalHeader('x-wap-token',response["data"].unique_code);
            this.$store.state.connectedUserUniqueCode = response["data"].unique_code;
            this.$store.state.connectedUser = response["data"];
            this.isConnected = true;
            this.$store.state.isConnected = true;
            document.cookie = "uniquecode=" + response["data"].unique_code;
            this.$store.commit('SET_CREDITS',response["data"].credits)

            this.closeModal()
          }
          else {
            alert("Email ou mot de passe incorrect")
          }
        })
        .catch((response) => {
          console.log(response);
          console.log("catch");
          this.isConnected = false;
        });
    }
  },
}
</script>